import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import UtilNotify from '../../utils/UtilNotify';
import PayeeActions from '../../actions/PayeeActions';
import MyButtonSecondary from '../myButton/MyButtonSecondary';

const SelectPayeeValue = (props) => {
  const {
    pendingPayee,
    onChange,
    value,
    onChangeItem,
    onlySelect,
    ...otherProps
  } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const resultPayee = await PayeeActions.fillPaginationList({
        search: search,
        pagination: {
          page: 0,
          perPage: 500,
        },
        filter: {},
      });
      const data = [];
      if (isMounted()) {
        if (resultPayee.success) {
          if (resultPayee.totalRows) {
            const listAccountPayees = resultPayee.data.filter(
              (py) => py.type === PayeeActions.TYPE.ACCOUNT
            );
            const listPayees = resultPayee.data.filter(
              (py) => py.type === PayeeActions.TYPE.DEFAULT
            );

            if (listAccountPayees.length) {
              data.push({
                label: t('WORD_TRANSAFER'),
                title: t('WORD_TRANSAFER'),
                options: listAccountPayees.map((catc) => {
                  return {
                    ...catc,
                    key: `Payee_${catc.idPayee}`,
                    label: catc.payeeName,
                    value: catc.idPayee,
                  };
                }),
              });
            }
            if (listPayees.length) {
              data.push({
                label: t('WORD_SAVED_PAYEES'),
                title: t('WORD_SAVED_PAYEES'),
                options: listPayees.map((catc) => {
                  return {
                    ...catc,
                    key: `Payee_${catc.idPayee}`,
                    label: catc.payeeName,
                    value: catc.idPayee,
                  };
                }),
              });
            }
          }
          if (pendingPayee) {
            const findPendding = resultPayee?.data?.find(
              (p) => p.payeeName === pendingPayee || p.idPayee === pendingPayee
            );
            if (!findPendding) {
              data.push({
                label: t('WORD_PENDDING'),
                title: t('WORD_PENDDING'),
                options: [
                  {
                    key: `Payee_${pendingPayee}`,
                    label: pendingPayee,
                    value: pendingPayee,

                    payeeName: pendingPayee,
                    idPayee: pendingPayee,
                  },
                ],
              });
            }
          }
        }
      }
      setloading(false);
      setlist(data);
    };
    fill();
  }, [t, pendingPayee, search]);

  const handleOnChange = (e, ee) => {
    setSearch('');
    onChange && onChange(e);
    onChangeItem && onChangeItem(ee);
  };

  const handleCreatePayee = async () => {
    setloading(true);
    let result = await PayeeActions.insert({ payeeName: search });
    if (isMounted()) {
      if (result.success) {
        setTimeout(() => {
          // setopen(false);
          handleOnChange(result.data.idPayee, result.data);
          setloading(false);
        }, 1000);
      } else {
        setloading(false);
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  return (
    <Select
      {...otherProps}
      loading={loading}
      // defaultValue={pendingPayee}
      value={value || null}
      style={{
        width: 200,
      }}
      // filterOption={(input, option) => {
      //   return (option?.label ?? '')
      //     .toLowerCase()
      //     .includes(input || ''.toLowerCase());
      // }}
      optionFilterProp="label"
      allowClear={true}
      showSearch={true}
      onSearch={setSearch}
      onChange={handleOnChange}
      options={list}
      notFoundContent={
        search ? (
          <MyButtonSecondary
            onClick={handleCreatePayee}
            block
            ocon={<PlusCircleOutlined />}
            label={`Create Payee`}
            style={{ width: '100%' }}
          />
        ) : (
          'No Data'
        )
      }
      // dropdownRender={(menu) => {
      //   return (
      //     <>
      //       {menu}
      //       {search ? (
      //         <MyButtonSecondary
      //           onClick={handleCreatePayee}
      //           block
      //           ocon={<PlusCircleOutlined />}
      //           label={`Create Payee`}
      //           style={{ width: '100%' }}
      //         />
      //       ) : null}
      //     </>
      //   );
      // }}
    />
  );
};

export default SelectPayeeValue;
