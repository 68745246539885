// import { socketAction } from '../actions/socketAction';
import ApiLocalStorage from './ApiLocalStorage';
import { store } from './store';

let onAccountChange;

const ApiStorage = {
  setLanguage: (lang) => {
    store.dispatch({ type: 'SET_LANG', payload: lang });
  },
  setSelectedTheme: (theme) => {
    store.dispatch({ type: 'SET_SELECTED_THEME', payload: theme });
  },
  setCurrentUser: (user) => {
    store.dispatch({ type: 'SET_CURRENT_USER', payload: user });
    if (user.hasOwnProperty('sessionToken')) {
      ApiStorage.setAccessToken(user.sessionToken);
    }
    if (user.hasOwnProperty('lang')) {
      ApiStorage.setLanguage(user.lang);
    }
  },
  setAccessToken: (accessToken) => {
    store.dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });
  },
  getAccessToken: () => {
    const { accessToken } = store.getState();
    return accessToken;
  },
  getSelectedTheme: () => {
    const { selectedTheme } = store.getState();
    return selectedTheme;
  },
  setDeviceKey: (deviceKey) => {
    store.dispatch({ type: 'SET_DEVICEKEY', payload: deviceKey });
  },
  getDeviceKey: () => {
    const { deviceKey } = store.getState();
    return deviceKey;
  },
  setCurrentDb: (db) => {
    store.dispatch({ type: 'SET_CURRENT_DB', payload: db });
    // socketAction.refreshWebSocket();
  },
  setCurrentDbSettings: (settings) => {
    store.dispatch({ type: 'SET_CURRENT_DB_SETTINGS', payload: settings });
  },
  setCanEdit: (canEdit) => {
    store.dispatch({ type: 'SET_CANEDIT', payload: canEdit });
  },
  getCurrentDb: () => {
    const { currentDb } = store.getState();
    return currentDb;
  },
  getCurrentDbSettings: () => {
    const { currentDbSettings } = store.getState();
    return currentDbSettings;
  },
  getCurrentUser: () => {
    const { currentUser } = store.getState();
    return currentUser;
  },
  getCanEdit: () => {
    const { canEdit } = store.getState();
    return canEdit;
  },
  setSideMenuColapsed: (opened) => {
    store.dispatch({ type: 'SET_SIDE_MENU_OPENED', payload: opened });
  },
  setSidePanelOpen: (opened) => {
    store.dispatch({ type: 'SET_SIDE_PANEL_OPEN', payload: opened });
  },
  clearUser: () => {
    ApiStorage.setCurrentUser({});
    ApiStorage.setAccessToken('');
    ApiStorage.setCurrentDb({});
    ApiStorage.setCurrentDbSettings({});
    ApiStorage.setSideMenuColapsed(false);
    ApiStorage.setSidePanelOpen(false);
    ApiLocalStorage.removeItem(ApiLocalStorage.TEMP_REMEMBER_ME);
  },
  setOnAccountUpdated: (fn) => {
    onAccountChange = fn;
  },
  onAccountChangeed: (data) => {
    onAccountChange && onAccountChange(data);
  },
};

export default ApiStorage;
