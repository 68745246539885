import React, { useState } from 'react';
import MyCard from '../../../components/myCard/MyCard';
import LogoSVG from '../../../icons/LogoSVG';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import MyTextSecondary from '../../../components/myText/MyTextSecondary';
import FormApp from '../../../components/formApp/FormApp';
import InputEmailFormItem from '../../../components/myInputForm/InputEmailFormItem';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import { useForm } from 'antd/es/form/Form';
import MySpace from '../../../components/mySpace/MySpace';
import { useTranslation } from 'react-i18next';
import ReturnToLogIn from '../../../components/returnToLogIn/ReturnToLogIn';
import UsersActions from '../../../actions/UsersActions';
import UtilNotify from '../../../utils/UtilNotify';
import MyCaptcha from '../../../components/myGoogleCaptcha/MyCaptcha';
import useIsMounted from '@utilityjs/use-is-mounted';

const formName = 'forgot_password_form';

const ForgotPasswordRequest = (props) => {
  const { onNext } = props;

  const isMounted = useIsMounted();

  const [CaptchaCheck, setCaptchaCheck] = useState(false);

  const { t } = useTranslation();

  const [form] = useForm();

  const [saving, setSaving] = useState(false);

  const onFinish = async (values) => {
    setSaving(true);
    let result = await UsersActions.forgotPasswordRequest(values);
    if (result.success) {
      onNext && onNext(values);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
      setSaving(false);
    }
  };

  return (
    <MyCard className="login-card">
      <MySpace direction="vertical" size={'large'} style={{ marginTop: 10 }}>
        <LogoSVG size={50} />
        <MySpace
          direction="vertical"
          // size={'middle'}
          style={{ width: '70%', marginBottom: 20, marginTop: 20 }}
        >
          <MyTextTitle size={30}>{t('WORD_FORGOT_PASSWORD')}</MyTextTitle>
          <MyTextSecondary>{t('WORD_FORGOT_HELP')}</MyTextSecondary>
        </MySpace>
        <FormApp form={form} name={formName} onFinish={onFinish}>
          <InputEmailFormItem name="email" placeholder={t('WORD_EMAIL')} />
          <MyButtonMain
            fullWidth
            loading={saving}
            label={t('WORD_SEND')}
            form={formName}
            htmlType="submit"
            disabled={!CaptchaCheck}
          />
        </FormApp>
        <ReturnToLogIn style={{ marginTop: 15, marginBottom: 10 }} />
      </MySpace>
      <MyCaptcha
        onChange={() => {
          if (isMounted()) {
            setCaptchaCheck(true);
          }
        }}
      />
    </MyCard>
  );
};

export default ForgotPasswordRequest;
