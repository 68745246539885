import React from 'react';
import { Card } from 'antd';
import './MyCard.scss';

const MyCard = (props) => {
  const { children, className, style, fullHeight, onClick, onDoubleClick } =
    props;

  return (
    <Card
      style={{ height: fullHeight ? '100%' : '', ...style }}
      className={className || ''}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      bordered={false}
    >
      {children}
    </Card>
  );
};

export default MyCard;
