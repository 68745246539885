import axios from 'axios';
import { history } from '../App';
import ApiStorage from '../redux/ApiStorage';
import UtilNotify from './UtilNotify';
import UtilNumber from './UtilNumber';

let BASE_URL = process.env.REACT_APP_BASE_NODE_URL;

export const getBaseURL = () => {
  return `${BASE_URL}/api/v1/`;
};

export const getBaseURLShowImage = (src) => {
  const iconSrc = src
    ? src.startsWith('http')
      ? src
      : `${BASE_URL}/${src}`
    : '';
  return iconSrc;
};

const adapter = axios.create({
  baseURL: getBaseURL(),
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
  validateStatus: false,
  withCredentials: false,
  timeout: 15000,
});

export async function fetchFromUrlPOSTAsync(url, parameters = []) {
  return await fetchFromUrlPOSTAsyncMain(url, parameters, {});
}

export async function fetchFromUrlPOSTAsyncLong(url, parameters = []) {
  return await fetchFromUrlPOSTAsyncMain(url, parameters, { timeout: 600000 });
}

export async function fetchFromUrlPOSTAsyncDownloadFile(
  url,
  parameters = [],
  fileName
) {
  let response = await fetchFromUrlPOSTAsyncMain(url, parameters, {
    responseType: 'blob',
  });
  const blob = new Blob([response], { encoding: 'UTF-8' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName + '.tsv';
  link.click();
  return response;
}

export async function fetchFromUrlPOSTAsyncMain(
  url,
  parameters = [],
  adapterOptions
) {
  try {
    let currentDb = ApiStorage.getCurrentDb();

    let deviceKey = ApiStorage.getDeviceKey();
    if (!deviceKey) {
      deviceKey = UtilNumber.getNewUniqID();
      ApiStorage.setDeviceKey(deviceKey);
    }

    let dataPost = {
      device: 'web',
      offset: getTimeZone(),
      dbName: currentDb?.dbName,
      deviceKey: deviceKey.toString(),
    };

    if (Array.isArray(parameters)) {
      parameters.forEach((par) => {
        dataPost[par.key] = par.value;
      });
    } else {
      Object.assign(dataPost, parameters);
    }

    let token = ApiStorage.getAccessToken();
    if (token) {
      adapter.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common['Authorization'];
    }
    let result = await adapter.post(url, dataPost, adapterOptions);
    if (result.status === 401) {
      // history.push("/login",{force: true})
      // window.location.reload();
    }
    if (result.status === 410) {
      ApiStorage.clearUser();
      history.push({
        pathname: '/login',
        state: { force: true },
      });
      window.location.reload();
    }
    if (
      !result.data.success &&
      result.data.errMsg &&
      (result.data.errMsg.startsWith("Table 'moneyscope_") ||
        result.data.errMsg.startsWith('Unknown database'))
    ) {
      ApiStorage.setCurrentDb({});
      ApiStorage.setCurrentDbSettings({});
      history.push('/dbbudget/list/first', { force: true });
      window.location.reload();
    }
    return result.data;
  } catch (error) {
    console.warn('error = ', error);
    UtilNotify.notifyErrorServer(error.message);
    // history.push("/en/page_not_found", { force: true })
    // window.location.reload();
    return { success: false, errMsg: error.message };
  }
}

export async function fetchFromUrlGETAsync(url, parameters = []) {
  try {
    let dataPost = {
      device: 'web',
      offset: getTimeZone(),
    };

    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });
    let token = ApiStorage.getAccessToken();
    if (token) {
      adapter.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common['Authorization'];
    }
    let result = await adapter.get(url, {
      params: dataPost,
    });
    return result.data;
  } catch (error) {
    console.warn('error = ', error);
    return { success: false, errMsg: error.message };
  }
}

function getTimeZone() {
  let offset = new Date().getTimezoneOffset();
  const result = `+${Math.abs(offset)}`;
  return result;
}

export default adapter;
