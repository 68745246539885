import React from 'react';
import { Table } from 'antd';
import MyPagination from '../myPagination/MyPagination';

const MyTable = ({
  onRow,
  className,
  total = 0,
  pageSize = 10,
  onChangePage,
  page = 0,
  title,
  showHeader = true,
  onRowClick,
  onRowDoubleClick,
  ...otherProps
}) => {
  return (
    <div className="table-wrapper">
      <Table
        {...otherProps}
        className={`app-table ${className || ''}`}
        pagination={false}
        showHeader={showHeader}
        caption={title ? () => title : false}
        onRow={
          onRow ||
          ((record, rowIndex) => {
            return {
              onClick: onRowClick, // click row
              onDoubleClick: onRowDoubleClick, // double click row
            };
          })
        }
      />
      <MyPagination
        total={total}
        onShowSizeChange={(page, pageSize) => onChangePage(page, pageSize - 1)}
        pageSize={pageSize}
        onChange={onChangePage}
        current={page + 1}
      />
    </div>
  );
};

export default MyTable;
