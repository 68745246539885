import React from 'react';
import MyCard from '../../../components/myCard/MyCard';
import { Space } from 'antd';
import LogoSVG from '../../../icons/LogoSVG';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import ReactCodeInput from 'react-verification-code-input';
import { useNavigate } from 'react-router-dom';
import UsersActions from '../../../actions/UsersActions';
import UtilNotify from '../../../utils/UtilNotify';
import { useState } from 'react';

const TwoStepVerification = () => {
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);

  const handleComplete = async (value) => {
    setloading(true);
    const result = await UsersActions.verify2Fa(value);

    if (!result.success) {
      setloading(false);
      UtilNotify.notifyErrorServer(result.errMsg);
      return;
    }

    navigate('/home', { replace: true });
  };

  return (
    <MyCard className="login-card two_factor_authentication">
      <Space direction="vertical" size={'large'} style={{ marginTop: 10 }}>
        <LogoSVG size={50} />
        <Space
          direction="vertical"
          size={'middle'}
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          <MyTextTitle size={30}>Please enter 2FA code</MyTextTitle>
        </Space>
        <ReactCodeInput
          fields={6}
          type="number"
          onComplete={handleComplete}
          loading={loading}
          autoFocus={true}
          className={'code-input'}
        />
      </Space>
    </MyCard>
  );
};

export default TwoStepVerification;
