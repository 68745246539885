import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_SAVE_UPDATE = '/cdb/payees/update';
const URL_SAVE_CREATE = '/cdb/payees/create';
const URL_GET_ALL = '/cdb/payees/get_all';
const URL_DELETE = '/cdb/payees/delete';

const fs = {
  insert: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_SAVE_CREATE, {
      saveData: values,
    });
    return result;
  },
  update: async (values, idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_SAVE_UPDATE + '/' + idKey, {
      saveData: values,
    });
    return result;
  },
  delete: async (idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_DELETE + '/' + idKey, []);
    return result;
  },
  fillPaginationList: async (params) => {
    let result = await fetchFromUrlPOSTAsync(URL_GET_ALL, params);
    return result;
  },
};

const loc = {
  STATUS: { ACTIVE: 1, HIDDEN: 2, DELETED: 3 },
  TYPE: { DEFAULT: 1, OPERATION: 2, ACCOUNT: 4, Pendding: 10 },
};

const PayeeActions = Object.assign(fs, loc);

export default PayeeActions;
