import React from 'react';
import { Form } from 'antd';
import UtilNumber from '../../utils/UtilNumber';
import LoadingIconApp from '../loadingIconApp/LoadingIconApp';

const FormApp = ({
  children,
  name = 'FormApp',
  className = '',
  validateTrigger = ['onBlur', 'onChange'],
  size = 'middle',
  layout = 'vertical',
  autoComplete = 'off',
  onFinishFailed,
  loading,
  ...otherProps
}) => {
  const handleonFinishFailed = (error) => {
    console.warn('Error : ', error);
    onFinishFailed && onFinishFailed(error);
  };

  return (
    <Form
      name={name || UtilNumber.getNewUniqID()}
      className={`form-app ${className}`}
      onFinishFailed={handleonFinishFailed}
      autoComplete={autoComplete}
      scrollToFirstError
      requiredMark="optional"
      size={size}
      validateTrigger={validateTrigger}
      layout={layout}
      preserve={false}
      {...otherProps}
    >
      {loading ? <LoadingIconApp /> : children}
    </Form>
  );
};

export default FormApp;
