import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Select, Form } from 'antd';
import CategoriesActions from '../../actions/CategoriesActions';
import GetCategoryIcons from '../defaultBudgetCategories/GetCategoryIcons';
import UtilNotify from '../../utils/UtilNotify';
import MySpace from '../mySpace/MySpace';
import MyText from '../myText/MyText';
import MyButtonSecondary from '../myButton/MyButtonSecondary';
import AccountActions from '../../actions/AccountActions';

const SelectCategoryAndAccount = ({
  onChange,
  label,
  name,
  required,
  hidden,
  placeholder,
  className,
  onChangeItem,
  onChangeItemAccount,
  onChangeItemCategory,
  allowClear,
  disabled,
  transactionType,
  style,
  errorMessage,
  idAccountToRemove,
  cashedDataCategory,
  cashedDataAccount,
}) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [search, setSearch] = useState('');

  const errMessage =
    errorMessage || `${t('WORD_ERROR_PLEASE_SELECT')} ${placeholder || label}!`;

  const fillFS = useCallback(async () => {
    if (t) {
      let resultCategoryData = cashedDataCategory || [];
      setloading(true);
      if (!cashedDataCategory) {
        const resultCategory = await CategoriesActions.fillPaginationList({
          search: '',
          filter: {
            typeIncomeExpense: transactionType,
          },
        });
        if (isMounted()) {
          if (!resultCategory.success) {
            UtilNotify.notifyErrorServer(resultCategory.errMsg);
            setloading(false);
            return;
          }
          resultCategoryData = resultCategory.data;
        }
      }
      let resultAccountData = cashedDataAccount || [];
      if (!cashedDataAccount) {
        const resultAccounts = await AccountActions.fillPaginationList({
          search: '',
          pagination: {
            page: 0,
            perPage: 3000,
          },
          filter: {},
        });
        if (isMounted()) {
          if (!resultAccounts.success) {
            UtilNotify.notifyErrorServer(resultAccounts.errMsg);
            setloading(false);
            return;
          }
          resultAccountData = resultAccounts.data;
        }
      }

      if (isMounted()) {
        const listParentLevel = resultCategoryData.filter((cat) => {
          return !cat.idParent;
        });
        const listChildrenLevel = resultCategoryData.filter((cat) => {
          return (
            cat.typeMainChild === CategoriesActions.TYPEMAINCHILD.CHILD &&
            cat.idParent
          );
        });
        const dataCategory = listParentLevel
          ?.map((cate) => {
            const obj = {
              ...cate,
              key: `category_${cate?.idCategory}`,
              label: cate?.categoryName,
              options: listChildrenLevel
                ?.filter((cat) => cat.idParent === cate.idCategory)
                .map((catc) => {
                  return {
                    ...catc,
                    idCategoryAndAccount: catc.idKey,
                    key: `category_${catc?.idCategory}`,
                    value: catc.idKey,
                    label: catc.categoryName,
                  };
                }),
            };
            if (obj.typeMainChild === CategoriesActions.TYPEMAINCHILD.CHILD) {
              delete obj.options;
            }
            return obj;
          })
          .filter(
            (r) =>
              r.typeMainChild === CategoriesActions.TYPEMAINCHILD.CHILD ||
              r.options?.length
          );

        const dataAccount = [];

        for (const main of AccountActions.defaultAccountTypes) {
          let obj = { ...main };
          obj.label = main.accTyName;
          obj.key = `account_${main.idGroup}`;
          obj.isAccount = true;
          obj.options = resultAccountData
            .filter((it) => {
              return (
                it.idType.toString().startsWith(obj.idGroup) &&
                it.idAccount !== idAccountToRemove
              );
            })
            .map((ch) => {
              return {
                ...ch,
                idCategoryAndAccount: ch.idKey,
                isAccount: true,
                label: ch.accountName,
                value: ch.idKey,
                key: `account_${ch.idAccount}`,
              };
            });
          if (obj.options.length) {
            const totalAccountValue = obj.options.reduce(
              (previousValue, currentValue) =>
                previousValue + currentValue.currentBalance,
              0
            );
            obj.currentBalance = totalAccountValue;
            dataAccount.push(obj);
          }
        }
        setlist([...dataCategory, ...dataAccount]);
        setloading(false);
      }
    }
  }, [t, isMounted, transactionType]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  const handleOnChange = (e, ee) => {
    setSearch('');
    onChange && onChange(e);
    onChangeItem && onChangeItem(ee);
    if (ee.accountName) {
      onChangeItemAccount && onChangeItemAccount(ee);
    }
    if (ee.categoryName) {
      onChangeItemCategory && onChangeItemCategory(ee);
    }
  };

  return (
    <Form.Item
      name={name}
      label={label}
      hidden={hidden}
      className={`select-group-app select-category ${className}`}
      rules={[
        { required: required && !hidden, message: errMessage },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value) {
              return Promise.resolve();
            }
            if (getFieldValue(name) !== '') {
              return Promise.resolve();
            }
            return Promise.reject(errorMessage);
          },
        }),
      ]}
    >
      <Select
        style={{ width: '100%', ...style }}
        placeholder={placeholder || label || ''}
        onSelect={handleOnChange}
        onSearch={setSearch}
        showSearch={true}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        optionFilterProp="label"
        loading={loading}
        allowClear={allowClear}
        disabled={disabled}
        // options={list}
        notFoundContent={
          search ? (
            <MyButtonSecondary
              onClick={() => {}}
              block
              ocon={<PlusCircleOutlined />}
              label={`Create category`}
              style={{ width: '100%' }}
            />
          ) : (
            'No Data'
          )
        }
        // optionRender={(option) => (
        //   <MySpace fullWidth spaceBetween>
        //     <MySpace>
        //       <GetCategoryIcons icon={option.data.icon} />
        //       <MyText>{option.data.label}</MyText>
        //     </MySpace>
        //   </MySpace>
        // )}
      >
        {list.map((parent) =>
          // parent.typeMainChild === CategoriesActions.TYPEMAINCHILD.MAIN ? (
          parent?.options?.length ? (
            <Select.OptGroup key={parent.key} label={parent.label}>
              {parent?.options?.map((child) => (
                <Select.Option
                  {...child}
                  key={child.key}
                  value={child.value}
                  label={child.label}
                  style={
                    parent.isAccount
                      ? { backgroundColor: 'rgba(136, 112, 230, 0.04)' }
                      : {}
                  }
                >
                  <MySpace fullWidth spaceBetween>
                    <MySpace>
                      {child.isAccount ? null : (
                        <GetCategoryIcons icon={child.icon} />
                      )}
                      <MyText>{child.label}</MyText>
                    </MySpace>
                  </MySpace>
                </Select.Option>
              ))}
            </Select.OptGroup>
          ) : (
            <Select.Option
              {...parent}
              key={parent.key}
              value={parent.value}
              label={parent.label}
              style={
                parent.isAccount
                  ? { backgroundColor: 'rgba(136, 112, 130, 0.04)' }
                  : {}
              }
            >
              <MySpace fullWidth spaceBetween>
                <MySpace>
                  {parent.isAccount ? null : (
                    <GetCategoryIcons icon={parent.icon} />
                  )}
                  <MyText>{parent.label}</MyText>
                </MySpace>
              </MySpace>
            </Select.Option>
          )
        )}
      </Select>
    </Form.Item>
  );
};

export default SelectCategoryAndAccount;
