import React from 'react';
import { Upload, message, Form, Button, Space } from 'antd';
import { getBaseURL } from '../../utils/UrlHelper';
import ApiStorage from '../../redux/ApiStorage';
import { UploadOutlined } from '@ant-design/icons';

const URL_UPLOAD_FILE = 'file/upload/file';
const maxFileSize = 2;

const beforeUpload = (file) => {
  let fileTypes = ['.tsv'];
  const fileSize = file.size / 1024 / 1024;
  const isLt2M = fileSize < maxFileSize;
  if (!isLt2M) {
    message.error(`File size must be less than ${maxFileSize}MB!`);
  }
  return isLt2M && fileTypes.some((v) => file.name.includes(v));
};

const UploadFiles = (props) => {
  const { onChange, label, name, required, placeholder, hidden } = props;
  const errorMessage = 'File  is required';

  const handleChange = (info) => {
    if (info.file.status === 'done') {
      if (info.file.response) {
        onChange && onChange(info.file.response.data.path);
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Form.Item
      label={label}
      name={name}
      hidden={hidden}
      rules={[
        {
          required: required && !hidden,
          message: errorMessage || `Please select ${placeholder || label}!`,
        },
      ]}
      extra={`${maxFileSize} MB / File`}
      valuePropName="file"
      getValueFromEvent={(e) => {
        return (e?.fileList || [])
          .filter((fi) => {
            return fi.status === 'done' && fi.response.data.path;
          })
          .map((ffi) => {
            return ffi.response.data.path;
          });
      }}
    >
      <Upload
        className="file-uploader"
        name="file"
        action={getBaseURL() + URL_UPLOAD_FILE}
        headers={{
          authorization: `Bearer ${ApiStorage.getAccessToken()}`,
        }}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        maxCount={2}
        multiple={true}
      >
        <Space>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Space>
      </Upload>
    </Form.Item>
  );
};

export default UploadFiles;
