import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import ApiStorage from '../redux/ApiStorage';
import ApiLocalStorage from '../redux/ApiLocalStorage';
import UtilObject from '../utils/UtilObject';

const URL_LOGIN = '/users/login';
const URL_REGISTER = '/users/register';
const URL_FORGOT_PASSWORD_REQUEST = '/users/forgot_password';
const URL_FORGOT_PASSWORD_CHECK = '/users/forgot_password_confirm';
const URL_FORGOT_PASSWORD_CHANGE = '/users/reset_password';
const URL_ACTIVATE = '/users/send_user_verification_confirm';
const URL_RESEND_CODE = '/users/send_user_verification';
const URL_LOG_OUT = '/users/logout';
const URL_CHANGE_PROFILE_SETTINGS = '/users/change_profile_settings';
const URL_DELETE_PROFILE = '/users/delete_profile';
const URL_CHANGE_PASSWORD = '/users/change_profile_password';

const URL_SIGN_UP_2FA = '/users/2fa/signup';
const URL_SAVE_2FA = '/users/2fa/save';
const URL_TURN_OFF_2FA = '/users/2fa/turn_off';
const URL_VERIFY_2FA = '/users/2fa/verify';

const fs = {
  login: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LOGIN, urlParams);
    if (result.success) {
      ApiStorage.setCurrentUser(
        Object.assign(result.data, {
          verify2fasuccess: Boolean(urlParams?.verify2fasuccess),
        })
      );
    }
    return result;
  },
  register: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_REGISTER, {
      saveData: values,
    });
    if (result.success) {
      ApiStorage.setCurrentUser(result.data);
    }
    return result;
  },
  activate: async (code, email) => {
    const result = await fetchFromUrlPOSTAsync(URL_ACTIVATE + '/' + code, {
      saveData: { email },
    });
    if (result.success) {
      ApiStorage.setCurrentUser(result.data);
    }
    return result;
  },
  resendCode: async (email) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_RESEND_CODE + '/' + email,
      {}
    );
    return result;
  },
  forgotPasswordRequest: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FORGOT_PASSWORD_REQUEST, {
      saveData: urlParams,
    });
    return result;
  },
  forgotPasswordCheck: async (code, email) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_FORGOT_PASSWORD_CHECK + '/' + code,
      { saveData: { email } }
    );
    return result;
  },
  forgotPasswordChange: async (values, data) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_FORGOT_PASSWORD_CHANGE + '/' + data.accessToken,
      { saveData: values }
    );
    return result;
  },
  logOut: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_LOG_OUT, []);
    return result;
  },
  changeAccountSettings: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_CHANGE_PROFILE_SETTINGS, {
      saveData: values,
    });
    if (result.success) {
      ApiStorage.setCurrentUser(result.data);
    }
    return result;
  },
  deleteAccount: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_DELETE_PROFILE, []);
    return result;
  },
  changePassword: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_CHANGE_PASSWORD, {
      saveData: values,
    });
    return result;
  },
  signUp2Fa: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_SIGN_UP_2FA, {});
    return result;
  },
  turnOff2Fa: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_TURN_OFF_2FA, {
      saveData: values,
    });
    return result;
  },
  verify2Fa: async (digitCode) => {
    const result = await fetchFromUrlPOSTAsync(URL_VERIFY_2FA, {
      digitCode,
    });
    if (result.success) {
      let obj = ApiLocalStorage.getItem(ApiLocalStorage.TEMP_REMEMBER_ME);
      if (!UtilObject.isEmpty(obj)) {
        obj.verify2fasuccess = true;
        ApiLocalStorage.setItem(ApiLocalStorage.TEMP_REMEMBER_ME, obj);
      } else {
        obj = { ...result.data, verify2fasuccess: true };
      }
      ApiStorage.setCurrentUser(obj);
    }
    return result;
  },
  saveUp2Fa: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_SAVE_2FA, {
      saveData: values,
    });
    return result;
  },
};

const loc = {
  STATUS: { PENDING: 0, TRIAL: 1, ACTIVE: 2, BLOCKED: 3 },
};

const UsersActions = Object.assign(fs, loc);

export default UsersActions;
