import React from 'react';
import { Switch } from 'antd';
import MySpace from '../mySpace/MySpace';

const MySwitch = (props) => {
  const { label, checked, onChange, loading } = props;

  return (
    <MySpace direction="vertical">
      {label ? <span>{label}</span> : null}
      <Switch checked={checked} onChange={onChange} loading={loading} />
    </MySpace>
  );
};

export default MySwitch;
