import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
// import About from '../pages/About';
// import ErrorPage404 from '../pages/ErrorPage/ErrorPage404';
// import AppRoutePrivate from './AppRoutePrivate';
import Test from '../pages/Test';
import MainLayout from '../layouts/mainLayout/MainLayout';
// import CurrenciesList from '../pages/currencies/currenciesList/CurrenciesList';
// import CurrenciesEdit from '../pages/currencies/currenciesEdit/CurrenciesEdit';
// import Login from '../pages/users/login/Login';
// import Register from '../pages/users/register/Register';
// import Activate from '../pages/users/activate/Activate';
// import ForgotPassword from '../pages/users/forgotPassword/ForgotPassword';
// import UserSettings from '../pages/users/userSettings/UserSettings';
// import UserChangePassword from '../pages/users/userChangePassword/UserChangePassword';
// import BudgetCreate from '../pages/budgets/budgetCreate/BudgetCreate';
// import BudgetList from '../pages/budgets/budgetList/BudgetList';
// import BudgetShare from '../pages/budgets/budgetShare/BudgetShare';
// import BudgetEdit from '../pages/budgets/budgetEdit/BudgetEdit';
// import TwoStepVerification from '../pages/users/twoStepVerification/TwoStepVerification';
// import CategoriesList from '../pages/categories/CategoriesList';
// import SetUpNewTwoStepVerification from '../pages/users/setUpNewTwoStepVerification/SetUpNewTwoStepVerification';
// import TurnOffTwoStepCerification from '../pages/users/setUpNewTwoStepVerification/TurnOffTwoStepCerification';
// import UtilObject from '../utils/UtilObject';
import { useSelector } from 'react-redux';
import UtilObject from '../utils/UtilObject';
import Login from '../pages/users/login/Login';
import BlankLayout from '../layouts/blankLayout/BlankLayout';
import LoginMaterial from '../pages/users/login/LoginMaterial';
import MainLayoutMaterial from '../layouts/mainLayoutMaterial/MainLayoutMaterial';
import MaterialHome from '../pages/MaterialHome';
import MaterialActivity from '../pages/MaterialActivity';
import ForgotPassword from '../pages/users/forgotPassword/ForgotPassword';
import Register from '../pages/users/register/Register';
import BudgetList from '../pages/budgets/budgetList/BudgetList';
import UserSettings from '../pages/users/userSettings/UserSettings';
import BudgetCreate from '../pages/budgets/budgetCreate/BudgetCreate';
import BudgetEdit from '../pages/budgets/budgetEdit/BudgetEdit';
import BudgetShare from '../pages/budgets/budgetShare/BudgetShare';
import Activity from '../pages/activity/Activity';
import CategoriesList from '../pages/categories/CategoriesList';
import Activate from '../pages/users/activate/Activate';
import SetUpNewTwoStepVerification from '../pages/users/setUpNewTwoStepVerification/SetUpNewTwoStepVerification';
import ErrorPage404 from '../pages/ErrorPage/ErrorPage404';
import AppRoutePrivate from './AppRoutePrivate';
import TransactionList from '../pages/transactions/TransactionList';
import AccountList from '../pages/accounts/accountList/AccountList';
import UserChangePassword from '../pages/users/userChangePassword/UserChangePassword';
import TestPlaid from '../TestPlaid';
import TransactionImportXls from '../pages/transactions/transactionImportXls/TransactionImportXls';
import TwoStepVerification from '../pages/users/twoStepVerification/TwoStepVerification';

const AppRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentUser = useSelector((state) => {
    return state.currentUser;
  });

  useEffect(() => {
    if (location.pathname === '/activate') {
      if (UtilObject.isEmpty(currentUser)) {
        navigate('/login');
      }
    } else if (location.pathname === '/two_step_verification') {
      if (
        !UtilObject.isEmpty(currentUser) &&
        currentUser.is2FAenabled &&
        currentUser.verify2fasuccess
      ) {
        navigate('/home');
      }
    }
  }, [location, navigate, currentUser]);

  return (
    <Routes>
      <Route
        path="test"
        element={
          // <AppRoutePrivate>
          <MainLayout fullPage>
            <Test />
          </MainLayout>
          // </AppRoutePrivate>
        }
      />
      <Route
        path="testPlaid"
        element={
          // <AppRoutePrivate>
          <MainLayout fullPage>
            <TestPlaid />
          </MainLayout>
          // </AppRoutePrivate>
        }
      />
      <Route
        path="login"
        replace
        element={
          <BlankLayout fullPage>
            <Login />
          </BlankLayout>
        }
      />
      <Route
        path="register"
        replace
        element={
          <BlankLayout fullPage>
            <Register />
          </BlankLayout>
        }
      />
      <Route
        path="activate"
        replace
        element={
          <BlankLayout>
            <Activate />
          </BlankLayout>
        }
      />
      <Route
        path="forgot_password"
        replace
        element={
          <BlankLayout>
            <ForgotPassword />
          </BlankLayout>
        }
      />
      <Route
        path="activity"
        replace
        element={
          <AppRoutePrivate>
            <MainLayout fullPage>
              <Activity />
            </MainLayout>
          </AppRoutePrivate>
        }
      />
      <Route
        path="category/list"
        element={
          <MainLayout fullPage>
            <CategoriesList />
          </MainLayout>
        }
      />
      <Route
        path="dbbudget/list/first"
        element={
          <BlankLayout Top>
            <BudgetList isFirst />
          </BlankLayout>
        }
      />
      <Route
        path="dbbudget/list"
        element={
          <MainLayout fullPage>
            <BudgetList />
          </MainLayout>
        }
      />
      <Route
        path="dbbudget/create"
        element={
          <MainLayout>
            <BudgetCreate />
          </MainLayout>
        }
      />
      <Route
        path="dbbudget/create/first"
        element={
          <BlankLayout Top>
            <BudgetCreate isFirst />
          </BlankLayout>
        }
      />
      <Route
        path="dbbudget/settings"
        element={
          <MainLayout>
            <BudgetEdit />
          </MainLayout>
        }
      />
      <Route
        path="dbbudget/share"
        element={
          <MainLayout>
            <BudgetShare />
          </MainLayout>
        }
      />

      <Route
        path="users/account_settings"
        element={
          <AppRoutePrivate>
            <MainLayout>
              <UserSettings />
            </MainLayout>
          </AppRoutePrivate>
        }
      />
      <Route
        path="users/set_up_2_step_verification"
        element={
          <AppRoutePrivate>
            <MainLayout>
              <SetUpNewTwoStepVerification />
            </MainLayout>
          </AppRoutePrivate>
        }
      />
      <Route
        path="users/change_password"
        element={
          <AppRoutePrivate>
            <MainLayout>
              <UserChangePassword />
            </MainLayout>
          </AppRoutePrivate>
        }
      />

      <Route
        path="login/material"
        replace
        element={
          <BlankLayout>
            <LoginMaterial />
          </BlankLayout>
        }
      />
      <Route
        path="material"
        replace
        element={
          <MainLayoutMaterial>
            <MaterialHome />
          </MainLayoutMaterial>
        }
      />
      <Route
        path="/material/activity"
        replace
        element={
          <MainLayoutMaterial>
            <MaterialActivity />
          </MainLayoutMaterial>
        }
      />

      <Route
        path="transaction/list"
        replace
        element={
          <AppRoutePrivate>
            <MainLayout fullPage>
              <TransactionList />
            </MainLayout>
          </AppRoutePrivate>
        }
      />
      <Route
        path="transaction/import-xls"
        replace
        element={
          <AppRoutePrivate>
            <MainLayout fullPage>
              <TransactionImportXls />
            </MainLayout>
          </AppRoutePrivate>
        }
      />
      <Route
        path="accounts/list"
        element={
          <AppRoutePrivate>
            <MainLayout fullPage>
              <AccountList />
            </MainLayout>
          </AppRoutePrivate>
        }
      />
      <Route
        path="two_step_verification"
        element={
          <BlankLayout>
            <TwoStepVerification />
          </BlankLayout>
        }
      />

      {/* <Route path="currencies">
        <Route
          path="list"
          element={
            <AppRoutePrivate>
              <MainLayout fullPage>
                <CurrenciesList />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route
          path="edit/:_id"
          element={
            <AppRoutePrivate>
              <MainLayout>
                <CurrenciesEdit />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
      </Route>
      <Route
        path="two_step_verification"
        replace
        element={
          <BlanckLayout>
            <TwoStepVerification />
          </BlanckLayout>
        }
      />
      <Route
        path="register"
        replace
        element={
          <BlanckLayout>
            <Register />
          </BlanckLayout>
        }
      />
      <Route
        path="forgot_password"
        replace
        element={
          <BlanckLayout>
            <ForgotPassword />
          </BlanckLayout>
        }
      />
      <Route
        path="activity"
        replace
        element={
          <AppRoutePrivate>
            <MainLayout fullPage>
              <Activity />
            </MainLayout>
          </AppRoutePrivate>
        }
      />
      

      <Route path="category">
        <Route
          path="list"
          replace
          element={
            <AppRoutePrivate>
              <MainLayout fullPage noScroll>
                <CategoriesList />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route
          path="v2/list"
          replace
          element={
            <AppRoutePrivate>
              <MainLayout fullPage noScroll>
                <CategoriesListV2 />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
      </Route>
      <Route path="dbbudget">
        <Route
          path="create/first"
          replace
          element={
            <BlanckLayout>
              <BudgetCreate isNew isFirst />
            </BlanckLayout>
          }
        />
        <Route
          path="list/first"
          replace
          exact
          element={
            <BlanckLayout>
              <BudgetList isFirst />
            </BlanckLayout>
          }
        />
        <Route
          path="create"
          replace
          element={
            <MainLayout>
              <BudgetCreate isNew />
            </MainLayout>
          }
        />
        <Route
          path="settings"
          replace
          element={
            <AppRoutePrivate>
              <MainLayout>
                <BudgetEdit />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route
          path="list"
          replace
          exact
          element={
            <AppRoutePrivate>
              <MainLayout fullPage>
                <BudgetList />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route
          path="share"
          replace
          exact
          element={
            <AppRoutePrivate>
              <MainLayout>
                <BudgetShare />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
      </Route>
      <Route
        path="activate"
        replace
        element={
          <BlanckLayout>
            <Activate />
          </BlanckLayout>
        }
      />
      <Route path="users">
        <Route
          path="account_settings"
          element={
            <AppRoutePrivate>
              <MainLayout>
                <UserSettings />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route
          path="change_password"
          element={
            <AppRoutePrivate>
              <MainLayout>
                <UserChangePassword />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route
          path="set_up_2_step_verification"
          element={
            <AppRoutePrivate>
              <MainLayout maxWidth={600}>
                <SetUpNewTwoStepVerification />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route
          path="turn_off_2_step_verification"
          element={
            <AppRoutePrivate>
              <MainLayout>
                <TurnOffTwoStepCerification />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
      </Route>
      <Route
        path="about"
        element={
          <AppRoutePrivate>
            <MainLayout>
              <About />
            </MainLayout>
          </AppRoutePrivate>
        }
      />
     */}
      <Route
        path="home"
        replace
        element={<Navigate to={'/activity'} replace />}
      />
      <Route path="page_not_found" element={<ErrorPage404 />} />
      <Route path="/" index element={<Navigate to={'/login'} replace />} />
      <Route path="*" element={<Navigate to={'home'} />} />
    </Routes>
  );
};

export default AppRoute;
