import React, { memo, useEffect, useRef } from 'react';
import { useForm } from 'antd/es/form/Form';
import UtilObject from '../../utils/UtilObject';
import GetCategoryIcons from '../../components/defaultBudgetCategories/GetCategoryIcons';
import MyLink from '../../components/myLink/MyLink';
import MyText from '../../components/myText/MyText';
import MyProgressBar from '../../components/myProgressBar/MyProgressBar';
import FormApp from '../../components/formApp/FormApp';
import InputNumberFormItem from '../../components/myInputForm/InputNumberFormItem';
import UtilNumber from '../../utils/UtilNumber';
import MySpace from '../../components/mySpace/MySpace';

const CategoryListItemChild = (props) => {
  const {
    item,
    // onEdit,
    // onDelete,
    // onCreate,
    onClick,
    onDoubleClick,
    // className,
    // isEditing,
    onCancelBudget,
    onSaveBudget,
    selectedItem,
    editingKey,
  } = props;

  const [form] = useForm();
  const inputRef = useRef();
  const isEditing = Boolean(editingKey === item?.idKey);

  useEffect(() => {
    if (isEditing) {
      inputRef?.current?.focus();
      form.setFieldsValue({ budgetValue: item?.budgetValue });
    }
  }, [isEditing, form, item]);

  const onFinish = async (values) => {
    onSaveBudget && onSaveBudget(values.budgetValue, item);
  };

  const isSelected =
    !UtilObject.isEmpty(selectedItem) &&
    selectedItem.idCategory === item?.idCategory;

  return (
    <div
      style={{
        width: '99%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
      }}
      className={isSelected ? 'selectedStyle' : ''}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(item);
      }}
      onDoubleClick={onDoubleClick}
    >
      <MySpace fullWidth style={{ padding: 5 }}>
        <GetCategoryIcons icon={item?.icon} />
        <div>
          <MyLink
            style={{ marginBottom: 5 }}
            path="/transaction/list"
            state={{ categoryObject: item }}
          >
            <MyText bold>{item?.categoryName}</MyText>
          </MyLink>
          <MyProgressBar
            size="small"
            style={{ width: 250 }}
            totalSpent={Math.abs(item?.currentBalance)}
            totalBudgeted={item?.budgetValue}
            isSelected={isSelected}
          />
        </div>
      </MySpace>
      <div
        style={{
          // backgroundColor: 'red',
          width: '56%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {Boolean(isEditing) ? (
          <FormApp
            onFinish={onFinish}
            initialValues={{ budgetValue: item?.budgetValue }}
            form={form}
          >
            <InputNumberFormItem
              ref={inputRef}
              name="budgetValue"
              onBlur={() => form.submit()}
              onEscape={() => onCancelBudget && onCancelBudget()}
            />
          </FormApp>
        ) : (
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              // backgroundColor: 'green',
              minWidth: '15%',
              alignItems: 'center',
              justifyContent: 'center',
              height: 50,
            }}
          >
            <MyText>{UtilNumber.formatDouble(item?.budgetValue || 0)}</MyText>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            // backgroundColor: 'green',
            minWidth: '15%',
            alignItems: 'center',
            justifyContent: 'center',
            height: 50,
          }}
        >
          <MyText>{UtilNumber.formatDouble(item?.currentBalance || 0)}</MyText>
        </div>
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            // backgroundColor: 'green',
            minWidth: '15%',
            alignItems: 'center',
            justifyContent: 'center',
            height: 50,
          }}
        >
          <MyText>{UtilNumber.formatDouble(item?.currentAvilableFinal)}</MyText>
        </div>
      </div>
    </div>
  );
};

export default memo(CategoryListItemChild);
