import UtilDate from './UtilDate';
import UtilFormats from './UtilFormats';

const numberFormat1 = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const UtilNumber = {
  formatDouble: (value) => {
    if (isNaN(value)) {
      return '';
    }
    try {
      return UtilFormats.getUserCurrencyFormatWithValueForView(value);

      // return numberFormat1.format(value);
    } catch (error) {}
    return value;
  },
  formatDoubleOrginalValue: (value) => {
    if (isNaN(value)) {
      return '';
    }
    try {
      // return UtilFormats.getUserCurrencyFormatWithValueForView(value);

      const formatted = numberFormat1.format(value);
      return formatted.replace(/\.00$/, '');
    } catch (error) {}
    return value;
  },
  getNewUniqID: () => {
    return (
      Math.floor(Math.random() * (99999999999 - 1111111111)) +
      UtilDate.getDateToday().toDate().getTime()
    );
  },
  isNumber: (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, '') || '0';
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  },
  calcTwoNumbersPercentage: (num1, num2) => {
    if (num1 === 0) {
      return 0;
    }
    if (!num1) {
      num1 = 1;
    }
    if (!num2) {
      num2 = 1;
    }
    return Math.round(parseFloat((num1 / num2) * 100));
  },

  calcTwoNumbersPercentageDiff: (num1, num2) => {
    if (!num1) {
      num1 = 1;
    }
    if (!num2) {
      num2 = 1;
    }
    const percentageChange = ((num1 - num2) / Math.abs(num2)) * 100;
    return UtilNumber.formatPercentages(percentageChange);
  },

  formatPercentages: (val) => {
    if (Math.abs(val) >= 1000000) {
      return Math.round(val / 1000000) + 'M';
    } else if (Math.abs(val) >= 1000) {
      return Math.round(val / 1000) + 'K';
    } else {
      return Math.round(val).toString();
    }
  },

  formatAddPlusAtBegining: (percentage) => {
    let isPositive = UtilNumber.getIsPercentagePositive(percentage);
    if (isPositive === 0) {
      return 0 + '%';
    } else if (isPositive) {
      return '+' + percentage + '%';
    } else {
      return percentage + '%';
    }
  },
  getIsPercentagePositive: (percentage) => {
    if (percentage) {
      if (percentage === 0 || percentage === '0') {
        return 0;
      }
      if (
        percentage > 0 ||
        (typeof percentage === 'string' &&
          percentage[0] &&
          percentage[0] !== '-')
      ) {
        return true;
      }
    }
    return false;
  },
  calcLoan: (amount, percentage, months) => {
    if (percentage) {
      percentage = parseFloat(percentage?.toString()?.replaceAll(',', '.'));
    }

    const round = (x) => {
      return Math.round(x * 100) / 100;
    };

    let principal = amount;
    let interest = percentage / 100 / 12;
    let payments = months;

    let x = Math.pow(1 + interest, payments);
    let monthly = (principal * x * interest) / (x - 1);

    if (
      !isNaN(monthly) &&
      monthly !== Number.POSITIVE_INFINITY &&
      monthly !== Number.NEGATIVE_INFINITY
    ) {
      return {
        payment: round(monthly),
        total: round(monthly * payments),
        totalInterest: round(monthly * payments - principal),
      };
    } else {
      return { payment: '', total: '', totalInterest: '' };
    }
  },
};

export default UtilNumber;
