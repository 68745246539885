import React from 'react';
import themeDarkConfig from './theme/themeDarkConfig';
import themeLightConfig from './theme/themeLightConfig';
import { ConfigProvider, theme } from 'antd';
import 'antd/dist/reset.css';
import useMyTheme from './theme/useMyTheme';
// import Color from './Color.scss';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AppRoute from './appRoute/AppRoute';
import { ToastContainer } from 'react-toastify';
// import { ThemeProvider } from '@mui/material';
// import { lightTheme, darkTheme } from './theme/materialTheme';
import TagManager from 'react-gtm-module';
import './App.scss';

const { defaultAlgorithm, darkAlgorithm } = theme;

export const history = createBrowserHistory({ basename: '/en' });

function App() {
  const { isDark } = useMyTheme();

  // console.log(
  //   'Color.iconColorDark : Color.iconColorLight',
  //   Color.iconColorDark,
  //   Color.iconColorLight
  // );

  // useEffect(() => {
  //   const root = document.documentElement;
  //   root?.style.setProperty(
  //     '--icon-color',
  //     isDark ? Color.iconColorDark : Color.iconColorLight
  //   );
  // }, [isDark]);

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_TAG_MANAGER_KEY,
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDark ? darkAlgorithm : defaultAlgorithm,
        token: isDark ? themeDarkConfig : themeLightConfig,
      }}
    >
      {/* <ThemeProvider theme={isDark ? darkTheme : lightTheme}> */}
      <BrowserRouter history={history}>
        <AppRoute />
        <ToastContainer />
      </BrowserRouter>
      {/* </ThemeProvider> */}
    </ConfigProvider>
  );
}

export default App;
