import { UploadOutlined } from '@ant-design/icons';
import { Upload, message, Form, Button } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { getBaseURL } from '../../utils/UrlHelper';
import ApiStorage from '../../redux/ApiStorage';
import UtilNotify from '../../utils/UtilNotify';
// import UtilObject from "../../utils/UtilObject";

import axios from 'axios';
import { useTranslation } from 'react-i18next';

// const URL_UPLOAD_FILE = "file/upload/file";
const maxFileSize = 7;

const UploadFilesManuelStart = forwardRef((props, ref) => {
  const {
    onChange,
    label,
    name,
    required,
    placeholder,
    hidden,
    maxCount,
    multiple,
    fileTypes,
    className,
    onFail,
  } = props;

  const { t } = useTranslation();

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async (url, parameters) => {
    const finalUrl = `${getBaseURL()}${url}`;
    const formData = new FormData();
    formData.append('device', 'web');
    const deviceKey = ApiStorage.getDeviceKey();
    const currentDb = ApiStorage.getCurrentDb();
    formData.append('deviceKey', deviceKey);
    formData.append('dbName', currentDb?.dbName);

    if (Array.isArray(parameters)) {
      parameters.forEach((par) => {
        if (Array.isArray(par.value)) {
          formData.append(par.key, JSON.stringify(par.value));
        } else {
          formData.append(par.key, par.value);
        }
      });
    } else {
      Object.entries(parameters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
        // formData.append(key, value);
      });
    }

    if (fileList.length === 1) {
      formData.append('file', fileList[0]);
      // formData.append(name, fileList[0]);
    } else {
      fileList.forEach((file) => {
        formData.append('files[]', file);
      });
    }
    setUploading(true);
    const response = await axios.post(finalUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${ApiStorage.getAccessToken()}`,
      },
    });
    const result = response.data;
    if (result.success) {
      UtilNotify.notifySuccess('upload successfully.');
      onChange && onChange(result);
    } else {
      message.error('upload failed.');
      onFail && onFail();
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setUploading(false);
    // .then((response) => {
    //   const result = response.data;
    //   if (result.success) {
    //     UtilNotify.notifySuccess('upload successfully.');
    //     onChange && onChange(result);
    //   } else {
    //     onFail && onFail();
    //     UtilNotify.notifyErrorServer(result.errMsg);
    //   }
    //   setUploading(false);
    //   return result;
    // })
    // .catch((error) => {
    //   console.error('error :>> ', error);
    //   message.error('upload failed.');
    //   setUploading(false);
    // })
    // .finally(() => {
    //   setUploading(false);
    // });
  };

  useImperativeHandle(ref, () => ({
    startUpload: handleUpload,
  }));

  const beforeUpload = (file) => {
    let fileTypesList = Array.isArray(fileTypes) ? fileTypes : [fileTypes];
    const fileSize = file.size / 1024 / 1024;
    const isLt2M = fileSize < maxFileSize;
    if (!isLt2M) {
      message.error(`File size must be less than ${maxFileSize}MB!`);
      return false;
    }
    if (!fileTypesList.some((v) => file.name.includes(v))) {
      message.error(`File type should be ${fileTypes}`);
      return false;
    }
    setFileList([...fileList, file]);
    return false;
  };

  // const handleOnChange = (info) => {
  //     if (info.file.status !== 'uploading') {
  //     }
  //     if (info.file.status === 'done') {
  //         if (info?.file?.response) {
  //             const result = info.file.response;
  //             if (result.success) {
  //                 message.success(`${info.file.name} file uploaded successfully`);
  //                 onChange && onChange(result.data.path);
  //             } else {
  //                 message.error(result.errMsg);
  //             }
  //         }
  //     } else if (info.file.status === 'error') {
  //         message.error(`${info.file.name} file upload failed.`);
  //     }
  // }

  return (
    <Form.Item
      label={label}
      name={name}
      placeholder={placeholder || label}
      hidden={hidden}
      extra={`${maxFileSize} MB / File`}
      rules={[
        {
          required: required && !hidden,
          message: `${label || placeholder} ${t('WORD_IS_REQUIRED')}`,
        },
      ]}
      // valuePropName="file"
      // getValueFromEvent={(e) => {
      //     return (e?.fileList || [])
      //         .filter((fi) => {
      //             return fi.status === 'done' && fi.response.data.path;
      //         })
      //         .map((ffi) => {
      //             return ffi.response.data.path;
      //         });
      // }}
    >
      <Upload
        className={className || 'file-uploader'}
        // action={`${getBaseURL()}${URL_UPLOAD_FILE}`}
        // headers={{
        //     authorization: `Bearer ${ApiStorage.getAccessToken()}`,
        // }}
        fileList={fileList}
        // name={'file'}
        multiple={multiple || false}
        maxCount={maxCount || 1}
        // data={() => ({ device: 'web', deviceKey: ApiStorage.getDeviceKey() })}
        // onChange={handleOnChange}
        beforeUpload={beforeUpload}
        onRemove={(file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        }}
      >
        <Button icon={<UploadOutlined />} disabled={uploading}>
          Click to Upload
        </Button>
      </Upload>
    </Form.Item>
  );
});

export default UploadFilesManuelStart;
